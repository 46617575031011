import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

export default function InternalLink({ link, label }) {
  const isActive = ({ isPartiallyCurrent, location, isCurrent, href }) => {
    return isCurrent || (isPartiallyCurrent && href !== '/')
      ? { className: 'active-link' }
      : {};
  };
  return (
    <Link to={link} activeClassName="active-link" getProps={isActive}>
      {label}
    </Link>
  );
}

InternalLink.propTypes = {
  link: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
